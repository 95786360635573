import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { SpaceGroup } from '@memberspot/shared/model/community';

export interface SpaceGroupsState
  extends EntityState<SpaceGroup, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'space-groups' })
export class SpaceGroupsStore extends EntityStore<SpaceGroupsState> {
  constructor() {
    super();
  }
}
