import { Injectable } from '@angular/core';
import { SpaceGroup } from '@memberspot/shared/model/community';
import { of } from 'rxjs';

import { SpaceGroupsStore } from './state/space-groups.store';

@Injectable({
  providedIn: 'root',
})
export class SpaceGroupSyncService {
  constructor(private store: SpaceGroupsStore) {}

  saveGroups(groups: SpaceGroup[]) {
    this.store.set(groups);

    return of(groups);
  }

  updateInStore(group: SpaceGroup) {
    this.store.upsert(group.id, group);
  }

  updateMany(upt: (Partial<SpaceGroup> & { id: string })[]) {
    upt.forEach((u) => this.store.update(u.id, u));
  }

  removeFromStore(groupId: string) {
    this.store.remove(groupId);
  }
}
