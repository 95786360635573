import { BackendMethod } from '@memberspot/shared/model/backend';

import { AddReplyDto } from './dto/space-comment.dto';
import {
  ChangeGroupAndSpacePrioritiesDto,
  ChangePriorityDto,
  MoveSpaceDto,
} from './dto/space-priority.dto';
import { Space, SpaceProperties } from './models/space';
import { SpaceGroup, SpaceGroupProperties } from './models/space-group';
import { SpacePostCommentCreateProperties } from './models/space-post';

export const CommunitySpaceEndpoints = {
  GET_COMMUNITY_DATA: (schoolId: string) => ({
    url: `/community-space/${schoolId}`,
    method: BackendMethod.GET,
  }),
  GET_SPACE_GROUPS: (schoolId: string) => ({
    url: `/community-space/space-groups/${schoolId}`,
    method: BackendMethod.GET,
  }),
  CREATE_SPACE_GROUP: (data: SpaceGroupProperties) => ({
    url: `/community-space/space-groups/create`,
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_SPACE_GROUP: (data: SpaceGroup) => ({
    url: `/community-space/space-groups/update`,
    data,
    method: BackendMethod.POST,
  }),
  DELETE_SPACE_GROUP: (schoolId: string, id: string) => ({
    url: `/community-space/space-groups/delete/${schoolId}/${id}`,
    method: BackendMethod.DELETE,
  }),
  GET_SPACES: (schoolId: string) => ({
    url: `/community-space/spaces/${schoolId}`,
    method: BackendMethod.GET,
  }),
  CREATE_SPACE: (data: SpaceProperties) => ({
    url: `/community-space/spaces/create`,
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_SPACE: (data: Space) => ({
    url: `/community-space/spaces/update`,
    data,
    method: BackendMethod.POST,
  }),
  DELETE_SPACE: (schoolId: string, id: string) => ({
    url: `/community-space/spaces/delete/${schoolId}/${id}`,
    method: BackendMethod.DELETE,
  }),
  CHANGE_GROUP_ORDER: (data: ChangePriorityDto) => ({
    url: `/community-space/change-order/group`,
    data,
    method: BackendMethod.POST,
  }),
  CHANGE_SPACE_ORDER: (data: ChangePriorityDto) => ({
    url: `/community-space/change-order/space`,
    data,
    method: BackendMethod.POST,
  }),
  MOVE_SPACE_TO_GROUP: (data: MoveSpaceDto) => ({
    url: `/community-space/change-order/move-space`,
    data,
    method: BackendMethod.POST,
  }),
  CHANGE_ORDER: (data: ChangeGroupAndSpacePrioritiesDto) => ({
    url: `/community-space/change-order`,
    data,
    method: BackendMethod.POST,
  }),
  GET_MODERATORS: (
    schoolId: string,
    spaceId: string,
    searchQuery?: string,
    page?: number,
  ) => ({
    url: createUrl(
      `/community-space/moderators/${schoolId}/${spaceId}`,
      searchQuery,
      page,
    ),
    method: BackendMethod.GET,
  }),
  GET_MEMBERS: (
    schoolId: string,
    spaceId: string,
    searchQuery?: string,
    page?: number,
    joined?: boolean,
  ) => ({
    url: createUrl(
      `/community-space/members/${schoolId}/${spaceId}`,
      searchQuery,
      page,
      joined,
    ),
    method: BackendMethod.GET,
  }),
  REMOVE_FROM_SPACE: (schoolId: string, spaceId: string, uid: string) => ({
    url: `/community-space/members/${schoolId}/${spaceId}/remove-from-space/${uid}`,
    method: BackendMethod.POST,
  }),
  MAKE_MODERATOR: (schoolId: string, spaceId: string, uid: string) => ({
    url: `/community-space/moderators/${schoolId}/${spaceId}/make-moderator/${uid}`,
    method: BackendMethod.POST,
  }),
  REMOVE_MODERATOR: (schoolId: string, spaceId: string, uid: string) => ({
    url: `/community-space/moderators/${schoolId}/${spaceId}/remove-moderator/${uid}`,
    method: BackendMethod.POST,
  }),
  REMOVE_ALL_PUBLIC_JOINED: (schoolId: string, spaceId: string) => ({
    url: `/community-space/all-public-joined/${schoolId}/${spaceId}`,
    method: BackendMethod.DELETE,
  }),
};

export const CommunityClientEndpoints = {
  GET_COMMUNITY_DATA: (schoolId: string) => ({
    url: `/community-client/${schoolId}`,
    method: BackendMethod.GET,
  }),
};

export const SpaceUserEndpoints = {
  JOIN_SPACE: (schoolId: string, spaceId: string) => ({
    url: `/community-space-user/join/${schoolId}/${spaceId}`,
    method: BackendMethod.POST,
  }),
  LEAVE_SPACE: (schoolId: string, spaceId: string) => ({
    url: `/community-space-user/leave/${schoolId}/${spaceId}`,
    method: BackendMethod.POST,
  }),
  UNSUBSCRIBE_FROM_SPACE: (schoolId: string, spaceId: string) => ({
    url: `/community-space-user/unsubscribe/${schoolId}/${spaceId}`,
    method: BackendMethod.PATCH,
  }),
  SUBSCRIBE_TO_SPACE: (schoolId: string, spaceId: string) => ({
    url: `/community-space-user/subscribe/${schoolId}/${spaceId}`,
    method: BackendMethod.PATCH,
  }),
};

export const SpacePostCommentEndpoints = {
  ADD_COMMENT: (dto: SpacePostCommentCreateProperties) => ({
    url: `/community-space-post-comment/create`,
    data: dto,
    method: BackendMethod.POST,
  }),
  ADD_REPLY: (dto: AddReplyDto) => ({
    url: `/community-space-post-comment/reply`,
    data: dto,
    method: BackendMethod.POST,
  }),
  DELETE_COMMENT: (
    schoolId: string,
    spaceId: string,
    spacePostId: string,
    spacePostCommentId: string,
  ) => ({
    url: `/community-space-post-comment/${schoolId}/${spaceId}/${spacePostId}/${spacePostCommentId}`,
    method: BackendMethod.DELETE,
  }),
  GET_OLDER_COMMENTS: (
    schoolId: string,
    spaceId: string,
    spacePostId: string,
    pageSize: number,
    lastLoadedId?: string,
  ) => ({
    url: `/community-space-post-comment/get-older/${schoolId}/${spaceId}/${spacePostId}?pageSize=${pageSize}&lastLoadedId=${lastLoadedId}`,
    method: BackendMethod.GET,
  }),
  GET_COMMENTS_FIRST_PAGE: (
    schoolId: string,
    spaceId: string,
    spacePostId: string,
    pageSize: number,
  ) => ({
    url: `/community-space-post-comment/first-page/${schoolId}/${spaceId}/${spacePostId}?pageSize=${pageSize}`,
    method: BackendMethod.GET,
  }),
  GET_OLDER_REPLIES: (
    schoolId: string,
    spaceId: string,
    spacePostId: string,
    spacePostCommentId: string,
    pageSize: number,
    lastLoadedId?: string,
  ) => ({
    url: `/community-space-post-comment/get-older-replies/${schoolId}/${spaceId}/${spacePostId}/${spacePostCommentId}?pageSize=${pageSize}&lastLoadedId=${lastLoadedId}`,
    method: BackendMethod.GET,
  }),
};

export const SpacePostFollowEndpoints = {
  SET_FOLLOW: (
    schoolId: string,
    spaceId: string,
    spacePostId: string,
    follow: boolean,
  ) => ({
    url: `/community-space-post/set-follow/${schoolId}/${spaceId}/${spacePostId}/${follow}`,
    method: BackendMethod.POST,
  }),
};

const createUrl = (
  baseUrl: string,
  searchQuery?: string,
  page?: number,
  joined?: boolean,
) => {
  const params = [];

  if (searchQuery) {
    params.push(`query=${searchQuery}`);
  }

  if (page) {
    params.push(`page=${page}`);
  }

  if (joined) {
    params.push(`joined=${joined}`);
  }

  return params.length ? `${baseUrl}?${params.join('&')}` : baseUrl;
};
